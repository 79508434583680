import React from "react";
import HowStep from "../reusables/HowStep";

const HowBody = () => {
  return (
    <section className=" section-spacing-80 no-padding-top">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content width-800 white-bg box-shadow relative margin-auto border-radius-2">
              <div className="section-body minus-margin-top-80">
                <div className=" padding-16">
                  <HowStep step="1" stepname="pre-requisites">
                    <p>Before one considers becoming a member:</p>
                    <ul>
                      <li>Must be a Muslim.</li>
                      <li>Must be of a sound mind.</li>
                      <li>
                        Not insolvent (should be in position to meet the minimum
                        shares).
                      </li>
                    </ul>
                    <p className=" margin-top-10">
                      Then have a one time, non-refundable membership fee of
                      60,000 UGX. Payable via CMC's Tropical Bank account or
                      mobile money depending on your convenience.
                    </p>
                    <p className=" margin-top-10">
                      You can reach out to us for more clarity:
                    </p>
                    <div className=" margin-top-10">
                      <p>
                        +256 752 542 761 / +256 701 345 290 / info@cmcug.com
                        <span className="block">
                          Prime Village Mall Natete, Masaka road, Kampala Uganda
                        </span>
                      </p>
                    </div>
                  </HowStep>
                  <HowStep step="2" stepname="buy ownership">
                    <p className=" margin-top-10">
                      After becoming a member, then you are elligible to buy
                      shares in the cooperative. Each share goes for ugx 5,000
                      and the minimum number of shares to be bought is 120
                      shares, equivalent to 600,000 UGX.
                    </p>
                    <p className=" margin-top-10">
                      The maximum number of shares a member can own is 120,000
                      shares. Which is equivalent to 30% of the total available
                      shares.
                    </p>
                  </HowStep>

                  <HowStep step="3" stepname="Get Dividends">
                    <p className=" margin-top-10">
                      The estimated share capital of Ugx 2bn will be used in
                      investing in projects like real estates, commodities,
                      education and other projects as will be agreedupon by
                      members.
                    </p>
                    <p className=" margin-top-10">
                      Since members are the share holders whose returns on
                      investments will be the dividends based on the shares
                      held.
                    </p>
                  </HowStep>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowBody;
