import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import logo from "../../assets/images/logo/logo.png";
import cmc from "../../assets/images/logo/cmc.png";
import map from "../../assets/images/icons/map.svg";
import clock from "../../assets/images/icons/clock.svg";
import email from "../../assets/images/icons/email.svg";

class Header extends Component {
  render() {
    const { location } = this.props;

    const aboutClass = location.pathname.match(/^\/about/) ? "active" : "";
    const howClass = location.pathname.match(/^\/how-cmc-works/)
      ? "active"
      : "";
    const contactClass = location.pathname.match(/^\/contact/) ? "active" : "";
    const galleryClass = location.pathname.match(/^\/gallery/) ? "active" : "";
    const welfareClass = location.pathname.match(/^\/welfare/) ? "active" : "";
    const teamClass = location.pathname.match(/^\/team/) ? "active" : "";
    return (
      <header className="site-header">
        <div className="header-banner threads-bg">
          <div className="container">
            <div className="large">
              <div className="section-content-container">
                <div className="section-content">
                  <div className="header-brand">
                    <div className="site-brand">
                      <a href="/" className="brand-logo block">
                        <img src={cmc} alt="CMC logo" className="auto" />
                      </a>
                    </div>
                    <div className="responsive-menu">
                      <div className="responsive-menu-container">
                        <label
                          htmlFor="toggle-responsive-menu"
                          className="nav-label bold-title"
                        >
                          &#9776;
                        </label>
                        <input type="checkbox" id="toggle-responsive-menu" />
                        <div className="show-responsive">
                          <span className="rich-small block center">
                            Tap on menu bars again to hide menu
                          </span>
                          <ul className="site-menu">
                            <li>
                              <a href="/">Home</a>
                            </li>
                            <li className="dropdown-menu">
                              <label
                                className="flex bold dark dropdown-menu-label"
                                htmlFor="toggle-dropdownresponsive-menu"
                              >
                                <span className="bold uppercase dark pointer">
                                  About
                                </span>
                              </label>
                              <input
                                type="checkbox"
                                id="toggle-dropdownresponsive-menu"
                              />

                              <ul className="dropdown" id="about-menu">
                                <li>
                                  <a href="/about" className={aboutClass}>
                                    About CMC
                                  </a>
                                </li>
                                <li>
                                  <a href="/team" className={teamClass}>
                                    CMC Leadership
                                  </a>
                                </li>
                                <li>
                                  <a href="/gallery" className={galleryClass}>
                                    Gallery
                                  </a>
                                </li>
                                <li>
                                  <a href="/welfare" className={welfareClass}>
                                    Welfare
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="/#projects">Projects</a>
                            </li>
                            <li>
                              <a href="/how-cmc-works" className={howClass}>
                                How CMC Works
                              </a>
                            </li>
                            <li>
                              <a href="/#contact">Contact Us</a>
                            </li>
                          </ul>
                          <div className="contact-details flex">
                            <div className="contact-item">
                              <img src={map} alt="Map" className="auto" />
                              <div>
                                <p className="bold contact-item-title">
                                  Visit Our Offices:
                                </p>
                                <p className="contact-item-subtitle">
                                  Prime Village Mall <br></br>
                                  Natete, Masaka rd, K'la.
                                </p>
                              </div>
                            </div>
                            <div className="contact-item">
                              <img src={clock} alt="Map" className="auto" />
                              <div>
                                <p className="bold contact-item-title">
                                  Opening Hours:
                                </p>
                                <p className="contact-item-subtitle">
                                  Mon-Fri: 9am-5pm <br></br>
                                  Sat-Sun: Closed
                                </p>
                              </div>
                            </div>
                            <div className="contact-item">
                              <img src={email} alt="Map" className="auto" />
                              <div>
                                <p className="bold contact-item-title">
                                  Send Email/Call:
                                </p>
                                <p className="contact-item-subtitle">
                                  info@cmcug.com <br></br>+256 702 143 323
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="contact-details flex"
                        id="responsive-contacts-details"
                      >
                        <div className="contact-item">
                          <img src={map} alt="Map" className="auto" />
                          <div>
                            <p className="bold contact-item-title">
                              Visit Our Offices:
                            </p>
                            <p className="contact-item-subtitle">
                              Prime Village Mall <br></br>
                              Natete, Masaka rd, K'la.
                            </p>
                          </div>
                        </div>
                        <div className="contact-item">
                          <img src={clock} alt="Map" className="auto" />
                          <div>
                            <p className="bold contact-item-title">
                              Opening Hours:
                            </p>
                            <p className="contact-item-subtitle">
                              Mon-Fri: 9am-5pm <br></br>
                              Sat-Sun: Closed
                            </p>
                          </div>
                        </div>
                        <div className="contact-item">
                          <img src={email} alt="Map" className="auto" />
                          <div>
                            <p className="bold contact-item-title">
                              Send Email/Call:
                            </p>
                            <p className="contact-item-subtitle">
                              info@cmcug.com <br></br>+256 702 143 323
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-contents threads-bg">
          <div className="container">
            <div className="large">
              <div className="section-content-container">
                <div className="section-content">
                  <nav className=" site-nav">
                    <div className="menu-container">
                      <label
                        htmlFor="toggle-responsive-menu"
                        className="nav-label bold-title"
                      >
                        &#9776;
                      </label>
                      <input type="checkbox" id="toggle-responsive-menu" />
                      <ul className="site-menu">
                        <li>
                          <a href="/">Home</a>
                        </li>
                        <li className="dropdown-menu">
                          <label
                            className="flex bold dark dropdown-menu-label"
                            htmlFor="toggle-dropdown-menu"
                          >
                            <span className="bold uppercase dark pointer">
                              About
                            </span>
                          </label>
                          <input type="checkbox" id="toggle-dropdown-menu" />

                          <ul className="dropdown" id="about-menu">
                            <li>
                              <a href="/about" className={aboutClass}>
                                About CMC
                              </a>
                            </li>
                            <li>
                              <a href="/team" className={teamClass}>
                                CMC Leadership
                              </a>
                            </li>
                            <li>
                              <a href="/gallery" className={galleryClass}>
                                Gallery
                              </a>
                            </li>
                            <li>
                              <a href="/welfare" className={welfareClass}>
                                Welfare
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="/#projects">Projects</a>
                        </li>
                        <li>
                          <a href="/how-cmc-works" className={howClass}>
                            How CMC Works
                          </a>
                        </li>
                        <li>
                          <a href="/#contact">Contact Us</a>
                        </li>
                        {/*<li className="menu-cta">
                          <a
                            href="https://form.jotform.com/221282307221544"
                            target="_blank"
                            className="button green-bg white bold"
                          >
                            Become a Member
                          </a>
                        </li>*/}
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
