import React from "react";
import twitter from "../../assets/images/icons/twitter.svg";
import facebook from "../../assets/images/icons/facebook.svg";
import instagram from "../../assets/images/icons/instagram.svg";

const Contacts = () => {
  return (
    <section
      className=" section-spacing-20 dark-green-bg threads-bg"
      id="contact"
    >
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className=" section-spacing-10">
              <div className=" title-container section-spacing-30 no-padding-top">
                <h4 className="title font-size-25 uppercase center">
                  You can reach out
                </h4>
              </div>
            </div>
            <div className="section-body">
              <div className="contacts-container flex space-flex-wrap font-size-16">
                <div className="address">
                  <h4 className="uppercase font-size-20 margin-bottom-10 bordered-bottom-4">
                    Contacts/Address
                  </h4>
                  <div className="brand-address">
                    <p>
                      +256 752 542 761 <br></br> +256 701 345 290 <br></br>{" "}
                      info@cmcug.com
                      <span className="block">
                        Prime Village Mall Natete,<br></br> Masaka road, Kampala
                        Uganda
                      </span>
                    </p>
                  </div>
                </div>
                <div className="working">
                  <h4 className="uppercase font-size-20 margin-bottom-10 center bordered-bottom-4">
                    Working hours
                  </h4>
                  <div className="working-inner center">
                    <p className="margin-bottom-10">
                      <span>Monday:</span>
                      <span className="bold left-push-4">
                        09:00AM - 05:00PM
                      </span>
                    </p>
                    <p className="margin-bottom-10">
                      <span>Tuesday:</span>
                      <span className="bold left-push-4">
                        09:00AM - 05:00PM
                      </span>
                    </p>
                    <p className="margin-bottom-10">
                      <span>Wednesday:</span>
                      <span className="bold left-push-4">
                        09:00AM - 05:00PM
                      </span>
                    </p>
                    <p className="margin-bottom-10">
                      <span>Friday:</span>
                      <span className="bold left-push-4">
                        09:00AM - 05:00PM
                      </span>
                    </p>
                    <p className="margin-bottom-10">
                      <span>Saturday:</span>
                      <span className="bold left-push-4">CLOSED</span>
                    </p>
                    <p className="margin-bottom-10">
                      <span>Sunday:</span>
                      <span className="bold left-push-4">CLOSED</span>
                    </p>
                  </div>
                </div>
                <div className="social">
                  <h4 className="uppercase font-size-20 margin-bottom-10 bordered-bottom-4">
                    Follow Us
                  </h4>
                  <div className="socials">
                    <a
                      href="https://twitter.com/CMCuganda"
                      target="_blank"
                      className="flex dark"
                    >
                      <img src={twitter} alt="Twitter" className="icon" />
                      <span className=" left-push-4">CMCuganda</span>
                    </a>
                    <a
                      href="https://facebook.com/CMCuganda"
                      target="_blank"
                      className="flex dark margin-top-10"
                    >
                      <img src={facebook} alt="Facebook" className="icon" />
                      <span className=" left-push-4">CMC Uganda</span>
                    </a>
                    <a
                      href="https://instagram.com/CMCuganda"
                      target="_blank"
                      className="flex dark margin-top-10"
                    >
                      <img src={instagram} alt="Instagram" className="icon" />
                      <span className=" left-push-4">CMCuganda</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-footer section-spacing-40 no-padding-bottom">
              <div className="cta-container center section-spacing-20 no-padding-top">
                <a
                  href="https://form.jotform.com/221281309713551"
                  className=" button bold more-button green width-48"
                >
                  Get in Touch
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contacts;
