import React from "react";
import Contacts from "../components/home/Contacts";
import PageHero from "../components/reusables/hero/PageHero";
import { Helmet } from "react-helmet";
import AgriBody from "../components/projects/agribusiness/AgriBody";

const Agribusiness = () => {
  return (
    <>
      <Helmet>
        <link rel="conanical" href="https://www.cmcug.org/team" />
        <meta
          name="description"
          content="CORPORATE MUSLIMS COMMUNITY's agri-business project"
        />
        <meta
          property="og:description"
          content="CORPORATE MUSLIMS COMMUNITY's agri-business project"
        />
        <meta property="og:title" content="CMC Agri-business project" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1650967307/cmcb_reqecj.jpg"
        />

        <meta name="twitter:title" content="CMC Agri-business project" />
        <meta name="twitter:text:title" content="CMC Agri-business project" />
        <meta
          name="twitter:description"
          content="CORPORATE MUSLIMS COMMUNITY's agri-business project"
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1650967307/cmcb_reqecj.jpg"
        />
        <title>CMC Agri-business project</title>
      </Helmet>
      <PageHero
        classes="dark-bg"
        titleClasses="title font-size-25 uppercase white"
        title="agri-business"
      />
      <AgriBody />
      <Contacts />
    </>
  );
};

export default Agribusiness;
