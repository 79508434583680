import React from "react";
import team from "../../assets/images/gallery/team.JPG";
import team1 from "../../assets/images/gallery/team1.JPG";
import GalleryCard from "../reusables/GalleryCard";

const Gallery = () => {
  return (
    <section className=" section-spacing-80 no-padding-top">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content width-800 white-bg box-shadow relative margin-auto border-radius-2">
              <div className="section-body minus-margin-top-80">
                <div className="padding-16 center">
                  <div className="section-head section-spacing-20">
                    <h4>AS IT HAPPENS AT OUR EVENTS</h4>
                  </div>
                  <div className="gallery-container">
                    <GalleryCard
                      galleryImage={team}
                      galleryImageCaption="Taken at our AGM"
                    />
                    <GalleryCard
                      galleryImage={team1}
                      galleryImageCaption="Taken at our AGM"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
