import React from "react";
import Faq from "../reusables/Faq";
import faqsFile from "../../assets/files/faqs.pdf";

const Faqs = () => {
  return (
    <section className=" section-spacing-100 no-padding-top">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-head">
              <div className="side-head">
                <div className="title-container">
                  <h3 className="section-title title uppercase">
                    Frequently asked questions
                  </h3>
                </div>
              </div>
            </div>
            <div className="section-body section-spacing-50 no-padding-bottom">
              <ul className="faqs-container">
                <Faq faq="What is a cooperative?">
                  <p>
                    According to International Cooperative Alliance [I.C.A.,
                    Manchester, U.K], 1995. “A cooperative is an autonomous
                    association of persons united voluntarily to meet their
                    common, economic, social and cultural needs and aspirations
                    through a jointly owned and democratically – controlled
                    enterprise”
                  </p>
                </Faq>
                <Faq faq="Where are CMC offices located?">
                  <p>
                    The offices are located Prime Village Mall Nateete, level 2,
                    room D06. You can also reach out to CMC on +256 752 542 761
                    / +256 701 345 290 / info@cmcug.com
                  </p>
                </Faq>
                <Faq faq="Who is eligible to become a member?">
                  <ul>
                    <li>Must be a Muslim.</li>
                    <li>Must be of a sound mind.</li>
                    <li>
                      Not insolvent (should be in position to meet the minimum
                      shares).
                    </li>
                  </ul>
                </Faq>
                <Faq faq="Have more questions?">
                  <div className="buttons-container flex section-spacing-10">
                    <a
                      href={faqsFile}
                      className="button dark bold more-button"
                      download
                    >
                      Download FAQs
                    </a>
                    <a
                      href="https://form.jotform.com/221282307221544"
                      className="button green-bg bold white"
                    >
                      Become a Member
                    </a>
                  </div>
                </Faq>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faqs;
