import React from "react";
import { Slide } from "react-reveal";
import people from "../../assets/images/content/coop.jpg";
import eye from "../../assets/images/icons/eye.svg";
import bull from "../../assets/images/icons/bull.svg";

const AboutSnippet = () => {
  return (
    <section className=" section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="sided-section-container flex">
              <Slide left>
                <div className="text-side left">
                  <div className="side-head">
                    <div className="title-container">
                      <h3 className="section-title title uppercase">
                        About CMC
                      </h3>
                    </div>
                  </div>
                  <div className="side-body section-spacing-40 font-size-16 bold">
                    <p>
                      CORPORATE MUSLIMS COMMUNITY (CMC) is a multi-purpose
                      cooperative, incorporated in 2019 as CORPORATE MUSLIMS
                      COMMUNITY MULTI-PURPOSE COOPERATIVE SOCIETY LTD and
                      registered in 2020 with the Ministry of Trade, Industries
                      and Cooperatives with an aim of spear-heading growth and
                      development within the muslim community.
                    </p>
                    <p className=" margin-top-20">
                      CMC intends to (1) Develop a saving culture with the
                      Muslim community for investment. (2) Enhance financial
                      independence among members. (3) Social development through
                      industrialization and real estate. (4) Uniting Muslims for
                      development and peace through sensitization and Dawah.
                    </p>
                    <p className=" margin-top-20">
                      CMC prides in having members from all sectors and academic
                      qualifications with the moral authority and expertise to
                      run the cooperative's projects and investments.
                    </p>
                    <p className=" margin-top-20">
                      Our head office is on Prime Village Mall Nateete, Adjacent
                      Total petrol station Masaka road, Kampala Uganda.
                    </p>
                  </div>
                  <div className="side-footer">
                    <div className="cta-container">
                      <a
                        href="/about"
                        className=" button bold green-bg white width-48"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </Slide>
              <Slide right>
                <div className="image-side">
                  <div className="image-container">
                    <img src={people} className="image border-radius-2" />
                  </div>
                  <div className="image-side-float-content">
                    <div className="image-side-text white text-shadow">
                      <div className="missvis">
                        <div className="vision">
                          <div className="flex justify-center">
                            <img src={eye} alt="Eye" className="auto" />
                            <h3>VISION</h3>
                          </div>
                          <p>
                            To be Africa's leading Islamic cooperative in
                            fostering socio-economic development
                          </p>
                        </div>
                        <div className="mission margin-top-20">
                          <div className="flex justify-center">
                            <img src={bull} alt="Eye" className="auto" />
                            <h3>MISSION</h3>
                          </div>
                          <p>Uniting for socio-development</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSnippet;
