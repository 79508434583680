import React from "react";
import agri from "../../../assets/images/content/agri.jpg";

const AgriBody = () => {
  return (
    <section className=" section-spacing-80 no-padding-top">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content width-800 white-bg box-shadow relative margin-auto border-radius-2">
              <div className="section-body minus-margin-top-80">
                <div className="padding-16 center">
                  <div className="section-head section-spacing-20">
                    <h4>Details coming soon</h4>
                  </div>
                  <img src={agri} alt="Agri-business" className="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AgriBody;
