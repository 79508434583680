import React from "react";
import AboutBody from "../components/about/AboutBody";
import PageHero from "../components/reusables/hero/PageHero";
import Contacts from "../components/home/Contacts";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
      <Helmet>
        <link rel="conanical" href="https://www.cmcug.org/about" />
        <meta name="description" content="About CORPORATE MUSLIMS COMMUNITY" />
        <meta
          property="og:description"
          content="About CORPORATE MUSLIMS COMMUNITY"
        />
        <meta
          property="og:title"
          content="About | CORPORATE MUSLIMS COMMUNITY"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1650967307/cmcb_reqecj.jpg"
        />

        <meta
          name="twitter:title"
          content="About | CORPORATE MUSLIMS COMMUNITY"
        />
        <meta
          name="twitter:text:title"
          content="About | CORPORATE MUSLIMS COMMUNITY"
        />
        <meta
          name="twitter:description"
          content="About CORPORATE MUSLIMS COMMUNITY"
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1650967307/cmcb_reqecj.jpg"
        />
        <title>CORPORATE MUSLIMS COMMUNITY | HOME</title>
      </Helmet>
      <PageHero
        classes="dark-bg"
        titleClasses="title font-size-25 uppercase white"
        title="About CMC"
      />
      <AboutBody />
      <Contacts />
    </>
  );
};

export default About;
