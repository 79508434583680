import React, { useState } from "react";
import ProjectModal from "./ProjectModal";
import { Slide, Fade } from "react-reveal";

const ProjectCard = (props) => {
  const [show, setShow] = useState(false);
  return (
    <div className="project">
      <Slide top>
        <div>
          <div className="project-image">
            <img
              src={props.projectImg}
              alt={props.projectTitle}
              className=" box-shadow"
            />
          </div>
          <p className="project-name">{props.projectTitle}</p>
        </div>
      </Slide>
      <div className="project-details margin-auto">
        <button
          onClick={() => setShow(true)}
          className="button more-button auto"
        >
          Project details
        </button>
        <ProjectModal
          projTitle={props.projectTitle}
          projImg={props.projectImg}
          projNotice={props.projectNotice}
          onClose={() => setShow(false)}
          show={show}
        >
          {props.children}
        </ProjectModal>
      </div>
    </div>
  );
};

export default ProjectCard;
