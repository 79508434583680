import React from "react";

import AboutSnippet from "../components/home/AboutSnippet";
import Contacts from "../components/home/Contacts";
import Faqs from "../components/home/Faqs";
import HomeHero from "../components/home/HomeHero";
import OurProjects from "../components/home/OurProjects";
import Team from "../components/home/Team";

import { Helmet } from "react-helmet";
import Welcome from "../components/home/Welcome";

export default function Home() {
  return (
    <>
      <Helmet>
        <link rel="conanical" href="https://www.cmcug.org/" />
        <meta
          name="description"
          content="CORPORATE MUSLIM COMMUNITY is a member owned multi-purpose cooperative where members unite for socio-economic development through buying shares as an investment in the cooperative's agreed upon and studied long-term projects that benefit the society and shareholders."
        />
        <meta
          property="og:description"
          content="CORPORATE MUSLIM COMMUNITY is a member owned multi-purpose cooperative where members unite for socio-economic development through buying shares as an investment in the cooperative's agreed upon and studied long-term projects that benefit the society and shareholders."
        />
        <meta
          property="og:title"
          content="CORPORATE MUSLIMS COMMUNITY | HOME"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1650967307/cmcb_reqecj.jpg"
        />

        <meta
          name="twitter:title"
          content="CORPORATE MUSLIMS COMMUNITY | HOME"
        />
        <meta
          name="twitter:text:title"
          content="CORPORATE MUSLIMS COMMUNITY | HOME"
        />
        <meta
          name="twitter:description"
          content="CORPORATE MUSLIM COMMUNITY is a member owned multi-purpose cooperative where members unite for socio-economic development through buying shares as an investment in the cooperative's agreed upon and studied long-term projects that benefit the society and shareholders."
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1650967307/cmcb_reqecj.jpg"
        />
        <title>CORPORATE MUSLIMS COMMUNITY | HOME</title>
      </Helmet>
      <HomeHero />
      <Welcome />
      <AboutSnippet />
      <OurProjects />
      <Team />
      {/*<WhyCMC />*/}
      <Faqs />
      <Contacts />
    </>
  );
}
