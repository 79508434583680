import React from "react";

const AboutBody = () => {
  return (
    <section className=" section-spacing-80 no-padding-top">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content width-800 white-bg box-shadow relative margin-auto border-radius-2">
              <div className="section-body minus-margin-top-80">
                <div className="text-content bold">
                  <h3 className="title font-size-25 uppercase bordered-title white">
                    BACKGROUND
                  </h3>
                  <p className="section-spacing-20 no-padding-bottom">
                    CORPORATE MUSLIMS COMMUNITY (CMC) is a multi-purpose
                    cooperative, incorporated in 2019 and registered in 2020
                    with the Ministry of Trade, Industries and Cooperatives.
                  </p>
                  <p>
                    CMC being a multi-purpose entity, we realized there is a
                    need to invest in Education, Health, Agri-business and
                    Real-estate.
                  </p>
                  <p>
                    One to be a member of CMC is required to buy shares. So they
                    won't just be members but also shareholders. A member's
                    sharehold determines their ownership of the cooperative.
                  </p>
                  <p>
                    Our head office is on Prime Village Mall Nateete, Adjacent
                    Total petrol station Masaka road, Kampala Uganda.
                  </p>
                  <p>
                    CMC has a team from every sector of qualification which
                    affords us a pool of knowledge and widening and deeping our
                    investment portfolios and their management.
                  </p>
                  <h3 className="title font-size-25 margin-top-20 uppercase bordered-title white">
                    OUr Mission
                  </h3>
                  <p>Uniting for socio-development</p>
                  <h3 className="title font-size-25 margin-top-20 uppercase bordered-title white">
                    OUr Vision
                  </h3>
                  <p>
                    To be Africa's leading Islamic cooperative in fostering
                    socio-economic development.
                  </p>
                  <h3 className=" title font-size-25 uppercase margin-top-20  bordered-title white">
                    {" "}
                    cmc objectives
                  </h3>
                  <p>CMC intends to: </p>
                  <ol>
                    <li>
                      Develop a saving culture with the Muslim community for
                      investment.{" "}
                    </li>
                    <li>
                      Enhance financial independence among members and the
                      muslim community.{" "}
                    </li>
                    <li>
                      Socio-economic development through industrialization and
                      real estate.{" "}
                    </li>
                    <li>
                      Uniting Muslims for development and peace through
                      sensitization and Dawah.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutBody;

{
  /*

<section>
        <div className="container">
          <div className="large">
            <div className="section-content-container">
              <div className="section-content width-800 white-bg box-shadow relative margin-auto border-radius-2">
                <div className="section-body minus-margin-top-80 section-height-300"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

*/
}
