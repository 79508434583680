import React from "react";
import Slider from "react-slick";
import { Slide } from "react-reveal";
import logo from "../../assets/images/logo/logo.png";
import goat1 from "../../assets/images/content/goat1.jpg";
import goat2 from "../../assets/images/content/goat2.jpg";
import apart1 from "../../assets/images/content/apart1.jpg";
import apart2 from "../../assets/images/content/rentals.jpg";
import wall from "../../assets/images/content/invest.jpg";
import health from "../../assets/images/content/health.jpg";
import school from "../../assets/images/content/school.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settings1 } from "../reusables/carousel";
import CarCard from "../reusables/CarCard";

const HomeHero = () => {
  return (
    <section className="hero">
      <div className="carous">
        <div className="slider-items-container center-slide-arrows">
          <Slider {...settings1}>
            <CarCard bgImg={health}>
              <Slide left>
                <div className="hero-content-container">
                  <div className="hero-content-inner threads-bg">
                    <div className="hero-head flex space-flex-wrap align-center">
                      <div className="brick threads-bg"></div>
                      <div className="brick-title"></div>
                      <div className="brick threads-bg"></div>
                    </div>
                    <div className="hero-body section-spacing-30 dark">
                      <p className="center bold">HEALTH</p>
                      <p className="center margin-top-10  font-size-16">
                        With intend to construct health facilities in agreed
                        upon areas and also partner with several health services
                        and plans providers to ensure good health in
                        communities.
                      </p>
                    </div>

                    <div className="hero-head flex space-flex-wrap align-center">
                      <div className="brick threads-bg"></div>
                      <div className="brick threads-bg"></div>
                    </div>
                  </div>
                </div>
              </Slide>
            </CarCard>
            <CarCard bgImg={wall}>
              <Slide right>
                <div className="hero-content-container">
                  <div className="hero-content-inner threads-bg">
                    <div className="hero-head flex space-flex-wrap align-center">
                      <div className="brick threads-bg"></div>
                      <div className="brick-title">
                        <p className=" bold dark">
                          <img src={logo} alt="CMC Logo" className="logo" />
                        </p>
                      </div>
                      <div className="brick threads-bg"></div>
                    </div>
                    <div className="hero-body section-spacing-30 dark">
                      <p className="center">
                        CORPORATE MUSLIMS COMMUNITY is a member owned
                        multi-purpose cooperative where members unite for
                        socio-economic development through buying shares as an
                        investment in the cooperative's agreed upon and studied
                        long-term projects that benefit the society and
                        shareholders.
                      </p>
                    </div>
                    <div className="hero-footer section-spacing-40 no-padding-top">
                      <div className="ctas-container flex">
                        <a
                          target="_blank"
                          href="https://wa.me/256702143323"
                          className="button green bold white-bg"
                        >
                          Chat with Admin
                        </a>
                        <a
                          href="https://form.jotform.com/221282307221544"
                          target="_blank"
                          className="button green-bg white bold"
                        >
                          Become a Member
                        </a>
                      </div>
                    </div>

                    <div className="hero-head flex space-flex-wrap align-center">
                      <div className="brick threads-bg"></div>
                      <div className="brick threads-bg"></div>
                    </div>
                  </div>
                </div>
              </Slide>
            </CarCard>
            <CarCard bgImg={goat2}>
              <Slide left>
                <div className="hero-content-container">
                  <div className="hero-content-inner threads-bg">
                    <div className="hero-head flex space-flex-wrap align-center">
                      <div className="brick threads-bg"></div>
                      <div className="brick-title">
                        <p className=" bold dark"></p>
                      </div>
                      <div className="brick threads-bg"></div>
                    </div>
                    <div className="hero-body section-spacing-30 dark">
                      <p className="center bold font-size-16">AGRI-BUSINESS</p>
                      <p className="center margin-top-10">
                        We are investing in Goats farming where we rear a number
                        of goat breeds that will provide meat for CMC's meat
                        processing factory.
                      </p>
                    </div>

                    <div className="hero-head flex space-flex-wrap align-center">
                      <div className="brick threads-bg"></div>
                      <div className="brick threads-bg"></div>
                    </div>
                  </div>
                </div>
              </Slide>
            </CarCard>
            <CarCard bgImg={school}>
              <Slide right>
                <div className="hero-content-container">
                  <div className="hero-content-inner threads-bg">
                    <div className="hero-head flex space-flex-wrap align-center">
                      <div className="brick threads-bg"></div>
                      <div className="brick-title">
                        <p className=" bold dark"></p>
                      </div>
                      <div className="brick threads-bg"></div>
                    </div>
                    <div className="hero-body section-spacing-30 dark">
                      <p className="center bold font-size-16">EDUCATION</p>
                      <p className="center margin-top-10">
                        We intend to invest in Education at all levels to put a
                        block on the social development of communities.
                      </p>
                    </div>
                    <div className="hero-head flex space-flex-wrap align-center">
                      <div className="brick threads-bg"></div>
                      <div className="brick threads-bg"></div>
                    </div>
                  </div>
                </div>
              </Slide>
            </CarCard>
            <CarCard bgImg={apart2}>
              <Slide left>
                <div className="hero-content-container">
                  <div className="hero-content-inner threads-bg">
                    <div className="hero-head flex space-flex-wrap align-center">
                      <div className="brick threads-bg"></div>
                      <div className="brick-title"></div>
                      <div className="brick threads-bg"></div>
                    </div>
                    <div className="hero-body section-spacing-30 dark">
                      <p className="center bold font-size-16">RENTALS</p>
                      <p className="center margin-top-10">
                        We intend to provide affordable &amp; quality
                        accomodation services through our rentals project.
                      </p>
                    </div>
                    <div className="hero-head flex space-flex-wrap align-center">
                      <div className="brick threads-bg"></div>
                      <div className="brick threads-bg"></div>
                    </div>
                  </div>
                </div>
              </Slide>
            </CarCard>
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
