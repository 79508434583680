import React from "react";
const HowStep = (props) => {
  return (
    <div className="member-card margin-bottom-20">
      <div className="member-step">
        <h3 className="step-number title">{props.step}</h3>
      </div>
      <div className="member-step">
        <h3 className="step-name title uppercase">{props.stepname}</h3>
      </div>
      <div className="member-step-content">{props.children}</div>
    </div>
  );
};

export default HowStep;
