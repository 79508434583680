import React from "react";
const Footer = () => {
  return (
    <section className="section-spacing-20 dark-bg threads-bg no-padding-bottom">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <ul className=" footer-menu flex">
                <li className="footer-menu-item footer-about">
                  <h3 className="footer-menu-title">About</h3>
                  <div className="footer-subtitle light-gray">
                    <p>
                      CMC has managed to recruit over 200 members since December
                      2019 within Uganda and outside countries and successfully
                      acquired land for factories and office setup.
                    </p>
                    <p className=" margin-top-10">
                      The cooperative's growth is on good trajectory and looks
                      forward to laying a developmental foundation for achieving
                      our goal of uniting muslims for socio-economic
                      development.
                    </p>
                    <p className=" margin-top-10">Join us today :)</p>
                  </div>
                </li>
                <li className="footer-menu-item footer-useful">
                  <h3 className="footer-menu-title">Useful Links</h3>
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/#projects">Projects</a>
                    </li>
                    <li>
                      <a
                        href="https://form.jotform.com/221281309713551"
                        target="_blank"
                      >
                        Contact Us
                      </a>
                    </li>
                    <li>
                      <a href="/how-cmc-works">How CMC works</a>
                    </li>
                    <li>
                      <a
                        href="https://form.jotform.com/221282307221544"
                        target="_blank"
                      >
                        Become a Member
                      </a>
                    </li>

                    <li>
                      <a href="/frequently-asked-questions">
                        Frequently Asked Questions
                      </a>
                    </li>

                    <li>
                      <a href="/team">Leadership Team & Committees</a>
                    </li>
                  </ul>
                </li>
                <li className="footer-menu-item">
                  <h3 className="footer-menu-title">Our Projects</h3>
                  <ul className="footer-books">
                    <li>
                      <a href="/projects/agri-business">
                        <span className="book-title-footer">
                          Agri-business{" "}
                        </span>
                        <span className="book-subtitle-footer">
                          Value addition to crops and selling end products under
                          CMC brand.
                        </span>
                        <span className="book-subtitle-footer block">
                          Rearing of goats for meat at our meat processing
                          factory.
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <span className="book-title-footer">Education</span>
                        <span className="book-subtitle-footer">
                          Investing in schools construction at all levels of
                          education.
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <span className="book-title-footer">Real Estate</span>
                        <span className="book-subtitle-footer">
                          Affordable and quality accomodation services through
                          our housing projects.
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <span className="book-title-footer">Health</span>
                        <span className="book-subtitle-footer">
                          Building of hospitals in designated locations around
                          the country.
                        </span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="copyright margin-top-20 center light-gray">
              <p>
                &copy; 2022 Corporate Muslims Community Multi-purpose
                Cooperative Society Ltd, All rights reserved
              </p>
            </div>
            <div className="designer section-spacing-10">
              <div className="design-inner center light-yellow">
                <p>
                  Website designed by{" "}
                  <a href="https://ntuna.com" className="link">
                    NTUNA.COM
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
