import React from "react";
const Modal = (props) => {
  return (
    <div className={`modal-contents-container ${props.show ? "show" : ""}`}>
      <div
        className=" modal-contents-inner modal-600 white-bg"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-close flex section-spacing-10">
          <button
            onClick={props.onClose}
            className="modal-close-content button modal-close-button"
          >
            Close
          </button>
        </div>
        <div className="modal-contents section-spacing-20 no-padding-bottom">
          <div className="modal-head center">
            <h3 className="modal-title uppercase font-size-20">
              {props.LeaderName}
            </h3>
          </div>
          <div className="modal-body section-spacing-20 padding-16 no-padding-bottom">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
