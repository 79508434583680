import React from "react";
import Contacts from "../components/home/Contacts";
import PageHero from "../components/reusables/hero/PageHero";
import { Helmet } from "react-helmet";
import Gallery from "../components/gallery/Gallery";

const CMCGallery = () => {
  return (
    <>
      <Helmet>
        <link rel="conanical" href="https://www.cmcug.org/gallery" />
        <meta
          name="description"
          content="CORPORATE MUSLIMS COMMUNITY's gallery of images as it happens at our events"
        />
        <meta
          property="og:description"
          content="CORPORATE MUSLIMS COMMUNITY's gallery of images as it happens at our events"
        />
        <meta property="og:title" content="CMC Gallery" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1650967307/cmcb_reqecj.jpg"
        />

        <meta name="twitter:title" content="CMC Gallery" />
        <meta name="twitter:text:title" content="CMC Gallery" />
        <meta
          name="twitter:description"
          content="CORPORATE MUSLIMS COMMUNITY's gallery of images as it happens at our events"
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1650967307/cmcb_reqecj.jpg"
        />
        <title>CMC Gallery</title>
      </Helmet>
      <PageHero
        classes="dark-bg"
        titleClasses="title font-size-25 uppercase white"
        title="CMC GALLERY"
      />
      <Gallery />
      <Contacts />
    </>
  );
};

export default CMCGallery;
