import React from "react";
import Contacts from "../components/home/Contacts";
import HowBody from "../components/how/HowBody";
import PageHero from "../components/reusables/hero/PageHero";
import { Helmet } from "react-helmet";

const How = () => {
  return (
    <>
      <Helmet>
        <link rel="conanical" href="https://www.cmcug.org/how-cmc-works" />
        <meta
          name="description"
          content="How CORPORATE MUSLIMS COMMUNITY Works"
        />
        <meta
          property="og:description"
          content="How CORPORATE MUSLIMS COMMUNITY Works"
        />
        <meta property="og:title" content="How CMC Works" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1650967307/cmcb_reqecj.jpg"
        />

        <meta name="twitter:title" content="How CMC Works" />
        <meta name="twitter:text:title" content="How CMC Works" />
        <meta
          name="twitter:description"
          content="How CORPORATE MUSLIMS COMMUNITY Works"
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1650967307/cmcb_reqecj.jpg"
        />
        <title>How CMC Works</title>
      </Helmet>
      <PageHero
        classes="green-bg"
        titleClasses="title font-size-25 uppercase white"
        title="how CMC works"
      />
      <HowBody />
      <Contacts />
    </>
  );
};

export default How;
