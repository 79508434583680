import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settings } from "../reusables/Constants";
import sec from "../../assets/images/content/sec.jpg";
import bashir from "../../assets/images/content/bashir.png";
import treasurer from "../../assets/images/content/treasurer.jpeg";
import chair from "../../assets/images/content/chair.png";
import yusuf from "../../assets/images/content/yusuf.jpg";
import bayat from "../../assets/images/content/bayat.jpg";
import nab from "../../assets/images/content/nab.jpg";
import ishak from "../../assets/images/content/ishak.JPG";
import TeamCardNo from "../reusables/TeamCardNo";

const Team = () => {
  return (
    <section className=" section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-head">
              <div className="side-head">
                <div className="title-container">
                  <h3 className="section-title title uppercase">
                    our leadership
                  </h3>
                </div>
              </div>
            </div>
            <div className="section-body section-spacing-40">
              <div className="slider-items-container center-slide-arrows flex space-flex-wrap">
                <Slider {...settings}>
                  <TeamCardNo
                    teamImg={chair}
                    name="Dr Ali Bananyiza"
                    job="Gombe Hospital"
                    title="Public Relations Officer"
                  >
                    <div className="left">
                      <div className="detail-item uppercase">
                        <p className="bold font-size-18">Position in cmc</p>
                        <p className="dark-gray margin-left-10">
                          Public Relations Officer
                        </p>
                      </div>
                    </div>
                  </TeamCardNo>
                  <TeamCardNo
                    teamImg={yusuf}
                    name="Mr. WAISWA YUSUF "
                    job="Edutech Media Group Ltd"
                    title="executive chairman"
                  >
                    <div className="left">
                      <div className="detail-item uppercase">
                        <p className="bold font-size-18">Position in cmc</p>
                        <p className="dark-gray margin-left-10">
                          executive chairman
                        </p>
                      </div>
                    </div>
                  </TeamCardNo>
                  <TeamCardNo
                    teamImg={nab}
                    name="Nabukalu Joweria"
                    job="Multichoice Uganda (DStv and GOtv)"
                    title="Vice Chairperson"
                  >
                    <div className="left">
                      <div className="detail-item uppercase">
                        <p className="bold font-size-18">Position in cmc</p>
                        <p className="dark-gray margin-left-10">
                          Vice Chairperson
                        </p>
                      </div>
                      <div className="detail-item uppercase">
                        <p className="bold font-size-18">current Employment</p>
                        <p className="dark-gray margin-left-10">
                          Multichoice Uganda (DStv and GOtv)
                        </p>
                      </div>
                    </div>
                  </TeamCardNo>
                  <TeamCardNo
                    teamImg={sec}
                    name="Ms. Nakazibwe Faridah"
                    job="Dora Single Mothers Foundation"
                    title="General Secretary"
                  >
                    <div className="left">
                      <div className="detail-item uppercase">
                        <p className="bold font-size-18">Position in cmc</p>
                        <p className="dark-gray margin-left-10">
                          General Secretary
                        </p>
                      </div>
                    </div>
                  </TeamCardNo>

                  <TeamCardNo
                    teamImg={treasurer}
                    name="Zabari Sheilla Ketra"
                    job="Uganda Development Bank"
                    title="Treasurer"
                  >
                    <div className="left">
                      <div className="detail-item uppercase">
                        <p className="bold font-size-18">Position in cmc</p>
                        <p className="dark-gray margin-left-10">Treasurer</p>
                      </div>
                      <div className="detail-item uppercase">
                        <p className="bold font-size-18">current Employment</p>
                        <p className="dark-gray margin-left-10">
                          Uganda Development Bank
                        </p>
                      </div>
                    </div>
                  </TeamCardNo>
                  <TeamCardNo
                    teamImg={bashir}
                    name="Omuk. KIZITO BASHIR JUMA"
                    job="Wakiso District Local Government"
                    title="CHAIRMAN Supervisory Board"
                  >
                    <div className="left">
                      <div className="detail-item uppercase">
                        <p className="bold font-size-18">Position in cmc</p>
                        <p className="dark-gray margin-left-10">
                          CHAIRMAN Supervisory Board
                        </p>
                      </div>
                    </div>
                  </TeamCardNo>
                  <TeamCardNo
                    teamImg={bayat}
                    name="Bayat Mpabulungi"
                    job="Bayatson Management Consultants Ltd"
                    title="Supervisory board Secretary"
                  >
                    <div className="left">
                      <div className="detail-item uppercase">
                        <p className="bold font-size-18">Position in cmc</p>
                        <p className="dark-gray margin-left-10">
                          Supervisory board Secretary
                        </p>
                      </div>
                      <div className="detail-item uppercase">
                        <p className="bold font-size-18">current Employment</p>
                        <p className="dark-gray margin-left-10">
                          Bayatson Management Consultants Ltd
                        </p>
                      </div>
                    </div>
                  </TeamCardNo>
                  <TeamCardNo
                    teamImg={ishak}
                    name="Kamihingo Ishaak M"
                    job="Concept Technologies And Solutions Ltd"
                    title="Executive Committee Member"
                  >
                    <div className="left">
                      <div className="detail-item uppercase">
                        <p className="bold font-size-18">Position in cmc</p>
                        <p className="dark-gray margin-left-10">
                          Executive Committee Member
                        </p>
                      </div>
                      <div className="detail-item uppercase">
                        <p className="bold font-size-18">current Employment</p>
                        <p className="dark-gray margin-left-10">
                          Concept Technologies And Solutions Ltd
                        </p>
                      </div>
                    </div>
                  </TeamCardNo>
                </Slider>
              </div>
            </div>
            <div className="section-footer section-spacing-20">
              <div className="cta-container center">
                <a
                  href="/team"
                  className=" button bold green-bg white width-48"
                >
                  View Full Team
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
