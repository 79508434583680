import React from "react";
import { Slide, Fade } from "react-reveal";

import ProjectCard from "../reusables/ProjectCard";
import goats from "../../assets/images/content/goat2.jpg";
import school from "../../assets/images/content/school.jpg";
import rentals from "../../assets/images/content/rentals.jpg";
import health from "../../assets/images/content/health.jpg";

const OurProjects = () => {
  return (
    <section className="section-spacing-100 black-bg" id="projects">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-head">
              <div className="side-head">
                <div className="title-container">
                  <h3 className="section-title title uppercase white">
                    Our Projects
                  </h3>
                </div>
              </div>
            </div>
            <div className="section-body section-spacing-40 no-padding-bottom">
              <div className="projects-container flex space-flex-wrap">
                <ProjectCard projectTitle="Agri-business" projectImg={goats}>
                  <div className="bold">
                    <p>
                      We bought the land where the agro-processing facilities
                      sit.
                    </p>
                    <p className=" margin-top-10">
                      We are investing in Goats farming where we rear a number
                      of goat breeds that will provide meat for CMC's meat
                      processing factory. The goats farm is CMC's first
                      agri-business project.
                    </p>
                    <p className=" margin-top-10">
                      We also intend to produce products like ready-to-eat
                      snacks and composite flour with the help of scientist from
                      Makerere University Food and Science department.
                    </p>
                  </div>
                </ProjectCard>
                <ProjectCard
                  projectTitle="Education"
                  projectImg={school}
                  projectNotice="coming soon"
                >
                  <div className="bold">
                    <p>
                      We intend to invest in Education at all levels to put a
                      block on the social development of communities.
                    </p>
                    <p className=" margin-top-10">
                      We shall have primary and secondary schools and technical
                      institutions to ensure students graduate and easily start
                      a life for themselves.
                    </p>
                  </div>
                </ProjectCard>
                <ProjectCard
                  projectTitle="Real Estate"
                  projectImg={rentals}
                  projectNotice="coming soon"
                >
                  <div className="bold">
                    <p>
                      Real estate is a sector on our radar for investment. We
                      intend to provide affordable and quality accomodation
                      services through our housing project.
                    </p>
                  </div>
                </ProjectCard>
                <ProjectCard
                  projectTitle="Health"
                  projectImg={health}
                  projectNotice="coming soon"
                >
                  <div className="bold">
                    <p className=" margin-top-10">
                      We intend to construct health facilities in agreed upon
                      areas and also partner with several health services and
                      plans providers to ensure good health in communities.
                    </p>
                  </div>
                </ProjectCard>
              </div>
              {/*
              <div className="cards-container">
                

                <Slide>
                  <ProjectCard
                    projectTitle="Health"
                    projectImg={health}
                    readMoreLink="#"
                    notice="Coming Soon"
                  >
                    <p className=" margin-top-10">
                      With intend to construct health facilities in agreed upon
                      areas and also partner with several health services and
                      plans providers to ensure good health in communities.
                    </p>
                  </ProjectCard>
                </Slide>
              </div>
              
              */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurProjects;
