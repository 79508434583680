import React from "react";
import Contacts from "../components/home/Contacts";
import PageHero from "../components/reusables/hero/PageHero";
import { Helmet } from "react-helmet";
import Gallery from "../components/gallery/Gallery";
import Welfare from "../components/welfare/Welfare";

const CMCWelfare = () => {
  return (
    <>
      <Helmet>
        <link rel="conanical" href="https://www.cmcug.org/gallery" />
        <meta
          name="description"
          content="CORPORATE MUSLIMS COMMUNITY's Welfare Funds"
        />
        <meta
          property="og:description"
          content="CORPORATE MUSLIMS COMMUNITY's Welfare Funds"
        />
        <meta property="og:title" content="CMC Welfare" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1650967307/cmcb_reqecj.jpg"
        />

        <meta name="twitter:title" content="CMC Welfare" />
        <meta name="twitter:text:title" content="CMC Welfare" />
        <meta
          name="twitter:description"
          content="CORPORATE MUSLIMS COMMUNITY's Welfare Funds"
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1650967307/cmcb_reqecj.jpg"
        />
        <title>CMC Welfare</title>
      </Helmet>
      <PageHero
        classes="dark-bg"
        titleClasses="title font-size-25 uppercase white"
        title="CMC Welfare"
      />
      <Welfare />
      <Contacts />
    </>
  );
};

export default CMCWelfare;
