import React from "react";

const Welfare = () => {
  return (
    <section className=" section-spacing-80 no-padding-top">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content width-800 white-bg box-shadow relative margin-auto border-radius-2">
              <div className="section-body minus-margin-top-80">
                <div className="padding-16 center">
                  <div className="welfare-section section-spacing-20">
                    <div className="section-head section-spacing-10 no-padding-top">
                      <h4>ABOUT CMC WELFARE</h4>
                    </div>
                    <div className="section-body section-height-300"></div>
                  </div>
                  <div className="welfare-section section-spacing-20">
                    <div className="section-head section-spacing-10 no-padding-top">
                      <h4>WELFARE COMMITTEE</h4>
                    </div>
                    <div className="section-body section-height-300"></div>
                  </div>
                  <div className="welfare-section section-spacing-20">
                    <div className="section-head section-spacing-10 no-padding-top">
                      <h4>WELFARE MEMBERS</h4>
                    </div>
                    <div className="section-body section-height-300"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Welfare;
