import React from "react";
import TeamCard from "../reusables/TeamCard";
import sec from "../../assets/images/content/sec.jpg";
import bashir from "../../assets/images/content/bashir.png";
import treasurer from "../../assets/images/content/treasurer.jpeg";
import chair from "../../assets/images/content/chair.png";
import yusuf from "../../assets/images/content/yusuf.jpg";
import bayat from "../../assets/images/content/bayat.jpg";
import ishak from "../../assets/images/content/ishak.JPG";
import ndya from "../../assets/images/content/ndya.jpeg";
import shakib from "../../assets/images/content/shakib.png";
import nab from "../../assets/images/content/nab.jpg";
import sep from "../../assets/images/content/sepp.jpg";


const TeamBody = () => {
  return (
    <section className=" section-spacing-80 no-padding-top">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content width-800 white-bg box-shadow relative margin-auto border-radius-2">
              <div className="section-body minus-margin-top-80">
                <div className=" padding-16">
                  <div className="com">
                    <div className="images-content">
                      <h3 className=" title font-size-25 uppercase bordered-bottom-4">
                        Executive board
                      </h3>
                      <div className="team-cards-container section-spacing-40 no-padding-bottom flex space-flex-wrap">
                        <TeamCard
                          teamImg={yusuf}
                          name="Mr. WAISWA YUSUF "
                          job="Edutech Media Group Ltd"
                          title="Chairman Executive Board"
                        >
                          <div className="left">
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                Position in cmc
                              </p>
                              <p className="dark-gray margin-left-10">
                                Chairman Executive Board
                              </p>
                            </div>
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                Qualifications
                              </p>
                              <ul className="margin-top-10 dark-gray">
                                <li>
                                  Bachelor of Business Studies from Islamic
                                  University in Uganda
                                </li>
                                <li>
                                  ACCA from Management Accountancy Training(MAT)
                                  school Uganda
                                </li>
                              </ul>
                            </div>
                            <div className="detail-item">
                              <p className="bold font-size-18 uppercase">
                                Profile
                              </p>
                              <div className="text-details font-size-16 dark-gray">
                                <p>Director Edutech Media Group Ltd</p>
                                <p>Co-founder Aama Food Industries Limited</p>
                                <p>
                                  Former finance director ishazi cooperative
                                </p>
                                <p>
                                  Worked with aponya Uganda limited as
                                  procurement officer
                                </p>
                              </div>
                            </div>
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                current Employment
                              </p>
                              <p className="dark-gray margin-left-10">
                                Edutech Media Group Ltd
                              </p>
                            </div>
                          </div>
                        </TeamCard>
                        <TeamCard
                          teamImg={nab}
                          name="Ms. Nabukalu Joweria"
                          job="Multichoice Uganda (DStv and GOtv)"
                          title="Vice Chairperson"
                        >
                          <div className="left">
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                Position in cmc
                              </p>
                              <p className="dark-gray margin-left-10">
                                Vice Chairperson
                              </p>
                            </div>
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                Qualifications
                              </p>
                              <ul className="margin-top-10 dark-gray">
                                <li>
                                  Bachelors in Business Computing from Makerere
                                  University Business School
                                </li>
                              </ul>
                            </div>
                            <div className="detail-item">
                              <p className="bold font-size-18 uppercase">
                                Profile
                              </p>
                              <div className="text-details font-size-16 dark-gray">
                                <p>
                                  Currently works as Support and Maintenance
                                  Customer Service Assistant at Multichoice
                                  Uganda (DStv and GOtv).
                                </p>
                              </div>
                            </div>
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                current Employment
                              </p>
                              <p className="dark-gray margin-left-10">
                                Multichoice Uganda (DStv and GOtv)
                              </p>
                            </div>
                          </div>
                        </TeamCard>
                        <TeamCard
                          teamImg={treasurer}
                          name="Zabari Sheilla Ketra Kaddu"
                          job="Uganda Development Bank"
                          title="Treasurer"
                        >
                          <div className="left">
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                Position in cmc
                              </p>
                              <p className="dark-gray margin-left-10">
                                Treasurer, Acc. Principal signatory
                              </p>
                            </div>
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                Qualifications
                              </p>
                              <ul className="margin-top-10 dark-gray">
                                <li>
                                  Masters in Public Admin and Management from
                                  Makerere University
                                </li>
                                <li>
                                  {" "}
                                  Bachelor of Arts in Social Sciences Makerere
                                  University
                                </li>
                                <li>
                                  Post Graduate Diploma in Finance Management
                                  (UMI)
                                </li>
                                <li>ACCA Level One (MAT)</li>
                                <li>
                                  Professional Certificate in Development
                                  Finance - (African Institute of Development
                                  Finance)
                                </li>
                              </ul>
                            </div>
                            <div className="detail-item">
                              <p className="bold font-size-18 uppercase">
                                Profile
                              </p>
                              <div className="text-details font-size-16 dark-gray">
                                <p>
                                  Verification Officer at Uganda Development
                                  Bank
                                </p>
                                <p>
                                  Former Senior Relationship Officer at Tropical
                                  Bank
                                </p>
                              </div>
                            </div>
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                current Employment
                              </p>
                              <p className="dark-gray margin-left-10">
                                Uganda Development Bank
                              </p>
                            </div>
                          </div>
                        </TeamCard>
                      </div>
                    </div>
                  </div>
                  <div className="com">
                    <div className="images-content">
                      <h3 className=" title font-size-25 uppercase bordered-bottom-4">
                        EXECUTIVE COMMITTEE MEMBERS
                      </h3>
                      <div className="team-cards-container section-spacing-40 no-padding-bottom flex space-flex-wrap">
                        <TeamCard
                          teamImg={ishak}
                          name="Kamihingo Ishaak M"
                          job="Concept Technologies And Solutions Ltd"
                          title="Executive Committee Member"
                        >
                          <div className="left">
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                Position in cmc
                              </p>
                              <p className="dark-gray margin-left-10">
                                Executive Committee Member, Special Duties Team,
                                Account Signatory
                              </p>
                            </div>
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                Qualifications
                              </p>
                              <ul className="margin-top-10 dark-gray">
                                <li>
                                  Bachelor of Information Technology of Islamic
                                  University In Uganda
                                </li>
                              </ul>
                            </div>
                            <div className="detail-item">
                              <p className="bold font-size-18 uppercase">
                                Profile
                              </p>
                              <div className="text-details font-size-16 dark-gray">
                                <p>
                                  Director/General Manager - Concept
                                  Technologies And Solutions Ltd - Electrical
                                  and solar Energy Experts
                                </p>
                              </div>
                            </div>
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                current Employment
                              </p>
                              <p className="dark-gray margin-left-10">
                                Concept Technologies And Solutions Ltd
                              </p>
                            </div>
                          </div>
                        </TeamCard>
                        <TeamCard
                          teamImg={sec}
                          name="Ms. Nakazibwe Faridah"
                          job="Dora Single Mothers Foundation"
                          title="General Secretary"
                        >
                          <div className="left">
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                Position in cmc
                              </p>
                              <p className="dark-gray margin-left-10">
                                General Secretary
                              </p>
                            </div>
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                Qualifications
                              </p>
                              <ul className="margin-top-10 dark-gray">
                                <li>
                                  Bachelor’s Degree in Business Computing from
                                  Makerere University Business School
                                </li>
                                <li>
                                  {" "}
                                  Certificate in Leadership Management from
                                  Makerere University Business School
                                </li>
                              </ul>
                            </div>
                            <div className="detail-item">
                              <p className="bold font-size-18 uppercase">
                                Profile
                              </p>
                              <div className="text-details font-size-16 dark-gray">
                                <p>
                                  Currently working as an Administrator with
                                  Dora Single Mothers Foundation (DSMF), an NGO
                                  crusading for empowerment of single mothers to
                                  be able to realise their Social, economic,
                                  political and wellbeing to successfully raise
                                  children and also be acceptable self-reliant
                                  members of the society.
                                </p>
                                <p>
                                  Experience in the banking sector specializing
                                  in micro finance and socioeconomic
                                  transformation of the mass segment after
                                  working with BRAC BANK Uganda Ltd for a period
                                  of 8 years.
                                </p>
                                <p>
                                  Great social mobiliser working as an
                                  Administrator in various Muslim Community
                                  driven assemblies like the Corporate Muslims
                                  Association, and the MUBs Muslim Alumni. She
                                  is Dedicated to helping the socially and
                                  economically unprivileged through charity
                                  initiatives.
                                </p>
                                <p>
                                  Ms. Nakazibwe Faridah, possess Hands on skills
                                  in project implementation and she is a
                                  practicing agro economist, currently
                                  undertaking a goat rearing project at Dorah
                                  single mother’s foundation.
                                </p>
                                <p>
                                  Politician at parish level, (Woman Councillor,
                                  Nakigoza Parish in Zirobwe Sub County, Luwero
                                  District) advocating for inclusion of the
                                  vulnerable groups and women.
                                </p>
                                <p>
                                  Contacts: Mobile number, +256-702143323/
                                  +256-782812720, Gmail: nakafady@gmail.com,
                                  Facebook: Nakazibwe Faridah, Instagram:
                                  nakazibwe. faridah.5, Twitter: nakazibwe
                                  faridah @NakazibweFari20
                                </p>
                              </div>
                            </div>
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                current Employment
                              </p>
                              <p className="dark-gray margin-left-10">
                                Dora Single Mothers Foundation
                              </p>
                            </div>
                          </div>
                        </TeamCard>
                        <TeamCard
                          teamImg={sep}
                          name="Sepuya Ismail"
                          job="Dreamland High School Entebbe"
                          title="executive Committee member "
                        >
                          <div className="left">
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                Position in cmc
                              </p>
                              <p className="dark-gray margin-left-10">
                                Chairman of legal and constitutional Committee,
                                Executive Committee Member
                              </p>
                            </div>
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                Qualifications
                              </p>
                              <ul className="margin-top-10 dark-gray">
                                <li>
                                  Soon completing a Bachelor in BIT of Islamic
                                  Call University
                                </li>
                              </ul>
                            </div>
                            <div className="detail-item">
                              <p className="bold font-size-18 uppercase">
                                Profile
                              </p>
                              <div className="text-details font-size-16 dark-gray">
                                <p>Teacher at Dreamland High School Entebbe</p>
                                <p>Area Imam of Taqwa Mosque Namulanda</p>
                                <p>Father of three as of May 2022</p>
                              </div>
                            </div>
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                current Employment
                              </p>
                              <p className="dark-gray margin-left-10">
                                Dreamland High School Entebbe
                              </p>
                            </div>
                          </div>
                        </TeamCard>
                      </div>
                    </div>
                  </div>
                  <div className="com">
                    <div className="images-content">
                      <h3 className=" title font-size-25 uppercase bordered-bottom-4">
                        SUPERVISORY COMMITTEE
                      </h3>
                      <div className="team-cards-container section-spacing-40 no-padding-bottom flex space-flex-wrap">
                        <TeamCard
                          teamImg={bashir}
                          name="Omuk. KIZITO BASHIR JUMA"
                          job="Wakiso District Local Government"
                          title="CHAIRMAN Supervisory Board"
                        >
                          <div className="left">
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                Position in cmc
                              </p>
                              <p className="dark-gray margin-left-10">
                                CHAIRMAN Supervisory Board, Account Signatory
                              </p>
                            </div>
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                Qualifications
                              </p>
                              <ul className="margin-top-10 dark-gray">
                                <li>
                                  Masters of Science in Environment &amp;
                                  Natural Resources management of Makerere
                                  University.
                                </li>
                                <li>
                                  Masters in Business Administration of the
                                  Uganda Management Institute
                                </li>
                                <li>
                                  Bachelor in Science degree in Surveying of
                                  Makerere University,
                                </li>
                                <li>
                                  Diploma in Law of the Law Development Centre,
                                </li>
                              </ul>
                            </div>
                            <div className="detail-item">
                              <p className="bold font-size-18 uppercase">
                                Profile
                              </p>
                              <div className="text-details font-size-16 dark-gray">
                                <p>
                                  Omuk. Kizito is a registered surveyor of
                                  Uganda (SRB no. 189)
                                </p>
                                <p>
                                  Fellow of the Institute of Surveyors of Uganda
                                  and a Managing Partner at Pearl Survey Land
                                  &amp; Mapping Associates.
                                </p>
                                <p>
                                  Serves as the District Staff Surveyor of
                                  Wakiso District Local Government.
                                </p>
                                <p>
                                  Served as the Branch Manager Finance Trust
                                  Bank, Masaka branch before retiring in 2008 to
                                  concentrate on the real estate industry.
                                </p>
                                <p>
                                  District staff Surveyor – Nakaseke District
                                  sitting at Bukalasa land office a position
                                  he’s held onto to date. Omuk Kizito also
                                  serves as the District Staff Surveyor of
                                  Wakiso District Local Government.
                                </p>
                                <p>
                                  Omuk Kizito also works at Buganda Land Board
                                  having joined them in 2009. While at Buganda
                                  Land Board, Omuk Kizito has served as Head of
                                  Department in a number of Departments. He
                                  currently serves as the Head of Operations,
                                  Business Development & Corporate Affairs.
                                </p>
                                <p>
                                  Omuk Kizito has demonstrated experience in
                                  project planning & portfolio management having
                                  been at the helm of the Kyapa Mu Ngalo
                                  project, Massive registration project, Busuulu
                                  Kyekibanja project, Land Electronic Card
                                  project, and the Lease Access Financing
                                  Initiative (LAFI) Project at Buganda Land
                                  Board.
                                </p>
                                <p>
                                  Omuk. Kizito serves on the Buladde Sacco, and
                                  is a Board member emeritus of the Buganda
                                  Heritage & Tourism Board.
                                </p>
                                <p>
                                  Omuk Kizito is a muslim, a sports enthusiast,
                                  Farmer and Family man.
                                </p>
                              </div>
                            </div>
                          </div>
                        </TeamCard>
                        <TeamCard
                          teamImg={bayat}
                          name="Bayat Mpabulungi"
                          job="Bayatson Management Consultants Ltd"
                          title="Supervisory board Secretary"
                        >
                          <div className="left">
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                Position in cmc
                              </p>
                              <p className="dark-gray margin-left-10">
                                Supervisory board Secretary
                              </p>
                            </div>
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                Qualifications
                              </p>
                              <ul className="margin-top-10 dark-gray">
                                <li>
                                  Bachelor of Arts in Business Administration
                                </li>
                                <li> ACCA</li>
                              </ul>
                            </div>
                            <div className="detail-item">
                              <p className="bold font-size-18 uppercase">
                                Profile
                              </p>
                              <div className="text-details font-size-16 dark-gray">
                                <p>
                                  Lead Partner at Bayatson Management
                                  Consultants Ltd
                                </p>
                                <p>
                                  Finance &amp; Admin Manager at Quality Clean
                                  Ltd, Kira Road Kamwokya
                                </p>
                                <p>Auditor at KAL Associates CPA</p>
                                <p>
                                  Accountant at Nyama Choma Restaurant Jinja
                                  road at Centenary park
                                </p>
                                <p>
                                  C.E.O at KaKo Unisex Beauty Parlour located in
                                  Komamboga on S&M plaza
                                </p>
                              </div>
                            </div>
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                current Employment
                              </p>
                              <p className="dark-gray margin-left-10">
                                Bayatson Management Consultants Ltd
                              </p>
                            </div>
                          </div>
                        </TeamCard>
                        <TeamCard
                          teamImg={ndya}
                          name="Jamiru Ndyanga"
                          job="Tropical Bank"
                          title="Supervisory Board Member"
                        >
                          <div className="left">
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                Position in cmc
                              </p>
                              <p className="dark-gray margin-left-10">
                                Supervisory Board Member
                              </p>
                            </div>
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                Qualifications
                              </p>
                              <ul className="margin-top-10 dark-gray">
                                <li>Bachelor of Economics and Statistics</li>
                                <li>
                                  Professional Qualification in Islamic Banking
                                  &amp; Finance
                                </li>
                              </ul>
                            </div>
                            <div className="detail-item">
                              <p className="bold font-size-18 uppercase">
                                Profile
                              </p>
                              <div className="text-details font-size-16 dark-gray">
                                <p>
                                  Currently Corporate Relationship Manager at
                                  Tropical Bank
                                </p>
                                <p>
                                  Formerly Research & Marketing Specialist at
                                  Tropical Bank
                                </p>
                                <p>
                                  Former Data Processing Assistant at Uganda
                                  Bureau of Statistics
                                </p>
                              </div>
                            </div>
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                current Employment
                              </p>
                              <p className="dark-gray margin-left-10">
                                Corporate Relationship Manager at Tropical Bank
                              </p>
                            </div>
                          </div>
                        </TeamCard>
                      </div>
                    </div>
                  </div>
                  <div className="com">
                    <div className="images-content">
                      <h3 className=" title font-size-25 uppercase bordered-bottom-4">
                        SPECIAL DUTIES
                      </h3>
                      <div className="team-cards-container section-spacing-40 no-padding-bottom flex space-flex-wrap">
                        
                        <TeamCard
                          teamImg={chair}
                          name="Dr Ali Bananyiza"
                          job="Gombe Hospital"
                          title="Public Relations Officer"
                        >
                          <div className="left">
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                Position in cmc
                              </p>
                              <p className="dark-gray margin-left-10">
                                Member corporate affairs committee and Public
                                Relations Officer.
                              </p>
                            </div>
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                Qualifications
                              </p>
                              <ul className="margin-top-10 dark-gray">
                                <li>MBchB of Makerere University</li>
                              </ul>
                            </div>
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                current Employment
                              </p>
                              <p className="dark-gray margin-left-10">
                                MD Gombe Hospital
                              </p>
                            </div>
                          </div>
                        </TeamCard>

                        <TeamCard
                          teamImg={shakib}
                          name="Shakib Ntale"
                          job="Equity Bank Uganda"
                          title="Vice Chairperson Executive Sub-Committee on Finance"
                        >
                          <div className="left">
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                Position in cmc
                              </p>
                              <p className="dark-gray margin-left-10">
                                Vice Chairperson Executive Sub-Committee on
                                Finance, Special duties- Accounts Signatory
                              </p>
                            </div>
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                Qualifications
                              </p>
                              <ul className="margin-top-10 dark-gray">
                                <li>
                                  Bachelor's Degree in Commerce from Makerere
                                  University
                                </li>
                              </ul>
                            </div>
                            <div className="detail-item">
                              <p className="bold font-size-18 uppercase">
                                Profile
                              </p>
                              <div className="text-details font-size-16 dark-gray">
                                <p>Relationship Officer-Equity Bank Uganda</p>
                              </div>
                            </div>
                            <div className="detail-item uppercase">
                              <p className="bold font-size-18">
                                current Employment
                              </p>
                              <p className="dark-gray margin-left-10">
                                Equity Bank Uganda
                              </p>
                            </div>
                          </div>
                        </TeamCard>
                        
                      </div>
                    </div>
                  </div>
                  <div className=" text-content">
                    <h3 className=" title font-size-25 uppercase margin-top-20 bordered-bottom-4">
                      committees in cmc
                    </h3>
                    <p>
                      CMC has several committes that make its leadership and
                      running. Here are the following;
                    </p>
                    <ul className=" margin-top-20">
                      <li>
                        <h4 className=" font-size-18 uppercase">
                          Supervisory Commitee (SUPCO)
                        </h4>
                        <p>This has the following responsibilities:</p>
                        <ul className="about-ul">
                          <li>
                            Periodically evaluate the programme of the
                            Cooperative as to their cost effectiveness, Impact
                            on the members’ income, compliance with the AGM
                            directions and Cooperative Societies Act, Rules and
                            the By-Laws of the society.
                          </li>
                          <li>
                            Check the effectiveness of the management committee,
                            e.g. recording and filling of the minutes of AGM and
                            committees.
                          </li>
                          <li>
                            Check whether cash is regularly verified and bank
                            reconciliations done.
                          </li>
                          <li>
                            Regularly check the performance ratios of the
                            society, e.g. cash ratios, solvency ratios.
                          </li>
                          <li>
                            Check whether members' personal Accounts (shares)
                            arc regularly and accurately updated.
                          </li>
                          <li>
                            Verify whether investments are properly accounted
                            for e.g. Share Certificates are securely kept.
                          </li>
                          <li>
                            Liaise with the external auditor in areas that the
                            committee would need assistance in carrying out
                            their duties.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h4 className=" font-size-18 uppercase">
                          CONSTITUTION AND LEGAL AFFAIRS COMMITTEE
                        </h4>
                        <p>This has the following responsibilities:</p>
                        <ul className="about-ul">
                          <li>
                            Draft the organization’s internal rules and
                            regulation to govern finance, membership,
                            procurement, operations, investment, corporate
                            relations and other activities based on the bylaws
                            and Islamic teachings.
                          </li>
                          <li>
                            Develop policies to be based on in distribution and
                            reinvestment of dividends.
                          </li>
                          <li>
                            Make sure all the organization transactions are made
                            within the governing rules and regulations both
                            internal and national.
                          </li>
                          <li>
                            Draft contracts and reviewing them on behalf of the
                            organization.
                          </li>
                          <li>
                            Ensure funds are used in accordance with the
                            governing laws.
                          </li>
                          <li>
                            Advise the executive committee on recruitment of
                            staff and organization management.
                          </li>
                          <li>
                            Draft laws to govern the welfare fund and its
                            distribution.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h4 className=" font-size-18 uppercase">
                          FINANCE AND ADMINISTRATION’S COMMITTEE
                        </h4>
                        <p>This has the following responsibilities:</p>
                        <ul className="about-ul">
                          <li>
                            Manage and monitor the organization’s finance.
                          </li>
                          <li>
                            Draft annual budgets and work plans to be used by
                            the organization.
                          </li>
                          <li>
                            Raise funds and make sure the organization has
                            enough funds to run.
                          </li>
                          <li>
                            Outsource assets, funds, goods, works and services
                            on behalf of the organization from government, NGO’s
                            friends and other sources available.
                          </li>
                          <li>
                            Timely acquisition or delivery of goods, services
                            and works.
                          </li>
                          <li>
                            Manage all procurement and disposal activities on
                            behalf of the organization.
                          </li>
                          <li>
                            Prepare and maintain the • organization’s books of
                            accounts.
                          </li>
                          <li>
                            Draft short term and long term financial plan and
                            set financial targets.
                          </li>
                          <li>
                            Ensure the organization has an appropriate capital
                            structure.
                          </li>
                          <li>
                            Prepare bid documents, adverts, bid opportunities
                            and also issue bidding documents.
                          </li>
                          <li>
                            Monitor the purchase, construction and installation
                            of the organization’s assets.
                          </li>
                          <li>
                            Set minimum shares to be bought by every member per
                            month to ensure continues inflow of startup capital.
                          </li>
                          <li>
                            Review the audit report and implement its finds.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h4 className=" font-size-18 uppercase">
                          BUSINESS DEVELOPMENT AND MARKETING COMMITTEE
                        </h4>
                        <p>This has the following responsibilities:</p>
                        <ul className="about-ul">
                          <li>
                            Help in CMC corporate brand development, product
                            development and other organizational identities.
                          </li>
                          <li>
                            Help in building and designing the organizational
                            official sites ie website, selling webs, social
                            media handles and other platforms
                          </li>
                          <li>
                            Draft annual management operating plans to ensure
                            consistency with the Strategic Directions.
                          </li>
                          <li>
                            Make sure the orgination products on market and
                            design all best marketing strategies to sell the
                            products
                          </li>
                          <li>
                            Develop, short-term and medium-term business plans,
                            focusing on implementing the organization core
                            objectives and long term plan
                          </li>
                          <li>
                            Develop strategies to make sure all members buy
                            shares and they are above the minimum shareholding.
                          </li>
                          <li>
                            Brand and sell CMC organization in Uganda and beyond
                            using websites, social media and other strategies.
                          </li>
                          <li>
                            Identify potential new sources of revenue which
                            could support the priority functions of the
                            organization
                          </li>
                          <li>
                            Suggest efficient and improved business models for
                            the organization activities
                          </li>
                          <li>
                            Suggest possible ways to implement the organization
                            business models
                          </li>
                          <li>Develop an investment strategy</li>
                        </ul>
                      </li>
                      <li>
                        <h4 className=" font-size-18 uppercase">
                          MEMBERSHIP AND CORPORATE AFFAIRS COMMITTEE
                        </h4>
                        <p>This has the following responsibilities:</p>
                        <ul className="about-ul">
                          <li>
                            Set strategic direction to guide and direct the
                            activities of the organization.
                          </li>
                          <li>
                            Monitor the activities, of the organization to
                            ensure they are in line with the founding
                            principles, objectives and values.
                          </li>
                          <li>
                            Develop strategies to attract new members and also
                            increase on membership of the organization.
                          </li>
                          <li>
                            Improve relationship between • cmc and other Islamic
                            organizations.
                          </li>
                          <li>
                            Brief new members about the • organization
                            objectives and its values.
                          </li>
                          <li>
                            Make sure the organization has all the necessary
                            records and they are kept safe.
                          </li>
                          <li>
                            Keep membership database and ensure individual
                            member records are up to date.
                          </li>
                          <li>
                            Create and maintain the corporate image of cmc in
                            the society and world at large
                          </li>
                          <li>
                            Make sure every member contribute to welfare fund
                            and their accounts are up to date.
                          </li>
                          <li>
                            Ensure CMC continuity by drafting strategies to
                            recruit more members.
                          </li>
                          <li>
                            Record and report all member individual issues to
                            executive committee.
                          </li>
                          <li>
                            To ensure all cmc members understand the
                            organization objective and their responsibilities.
                          </li>
                          <li>
                            To make sure all member benefit from welfare fund
                            and return on their investment.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamBody;
